import { OAC2021 } from "@/config/OAC2021";

const OAC2021UK = { ...OAC2021 };
const dataTable = "ukoac_a";

const cols = "the_geom_webmercator, oa21cd, oac21sup, oac21grp, oac21sub";

OAC2021UK.title = "UK-OAC Modelled";
OAC2021UK.sql = `SELECT ${cols} FROM ${dataTable}`;
OAC2021UK.buildingSql = `SELECT ${cols} FROM ${dataTable}_vmd23`;
OAC2021UK.detailUrbanSql = `SELECT ${cols} FROM ${dataTable}_bua22`;
OAC2021UK.urbanSql = OAC2021UK.detailUrbanSql;
OAC2021UK.description =
  "UK Output Area Classification 2021 (Modelled for Scotland and Northern Ireland)";
OAC2021UK.geogNamePrefix = "";
OAC2021UK.geogNameField = "oa21cd";
OAC2021UK.ladCodeField = "oa21cd";
OAC2021UK.extent = 9;
OAC2021UK.downloadLink = "https://data.cdrc.ac.uk/dataset/uk-oac-modelled";
OAC2021UK.relatedMaps = ["OAC", "LOAC2021", "OAC2021"];
OAC2021UK.makePolygonSQL = function(polygonSubquery) {
  return (
    OAC2021UK.polygonSQLPre +
    ` FROM oliverobrien.${dataTable} d
  inner join (${polygonSubquery}) p
  on st_intersects(d.the_geom, p.the_geom)`
  );
};

function LightenDarkenColor(col, amt) {
  var usePound = false;
  if (col[0] == "#") {
    col = col.slice(1);
    usePound = true;
  }

  var num = parseInt(col, 16);
  var r = (num >> 16) + amt;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;
  var b = ((num >> 8) & 0x00ff) + amt;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;
  var g = (num & 0x0000ff) + amt;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

// Changes the RGB/HEX temporarily to a HSL-Value, modifies that value
// and changes it back to RGB/HEX.

function changeHue(rgb, degree) {
  var hsl = rgbToHSL(rgb);
  hsl.h += degree;
  if (hsl.h > 360) {
    hsl.h -= 360;
  } else if (hsl.h < 0) {
    hsl.h += 360;
  }
  return hslToRGB(hsl);
}

// exepcts a string and returns an object
function rgbToHSL(rgb) {
  // strip the leading # if it's there
  rgb = rgb.replace(/^\s*#|\s*$/g, "");

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (rgb.length == 3) {
    rgb = rgb.replace(/(.)/g, "$1$1");
  }

  var r = parseInt(rgb.substr(0, 2), 16) / 255,
    g = parseInt(rgb.substr(2, 2), 16) / 255,
    b = parseInt(rgb.substr(4, 2), 16) / 255,
    cMax = Math.max(r, g, b),
    cMin = Math.min(r, g, b),
    delta = cMax - cMin,
    l = (cMax + cMin) / 2,
    h = 0,
    s = 0;

  if (delta == 0) {
    h = 0;
  } else if (cMax == r) {
    h = 60 * (((g - b) / delta) % 6);
  } else if (cMax == g) {
    h = 60 * ((b - r) / delta + 2);
  } else {
    h = 60 * ((r - g) / delta + 4);
  }

  if (delta == 0) {
    s = 0;
  } else {
    s = delta / (1 - Math.abs(2 * l - 1));
  }

  return {
    h: h,
    s: s,
    l: l
  };
}

// expects an object and returns a string
function hslToRGB(hsl) {
  var h = hsl.h,
    s = hsl.s,
    l = hsl.l,
    c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2,
    r,
    g,
    b;

  if (h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (h < 300) {
    r = x;
    g = 0;
    b = c;
  } else {
    r = c;
    g = 0;
    b = x;
  }

  r = normalize_rgb_value(r, m);
  g = normalize_rgb_value(g, m);
  b = normalize_rgb_value(b, m);

  return rgbToHex(r, g, b);
}

function normalize_rgb_value(color, m) {
  color = Math.floor((color + m) * 255);
  if (color < 0) {
    color = 0;
  }
  return color;
}

function rgbToHex(r, g, b) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

OAC2021UK.hierarchy = JSON.parse(JSON.stringify(OAC2021UK.hierarchy));

OAC2021UK.hierarchy.forEach(function(key) {
  key.groups.forEach(function(grp) {
    grp.colour = LightenDarkenColor(grp.colour, -35);
    grp.colour = changeHue(grp.colour, 20); //-30 is good
  });
});

export { OAC2021UK };
