import colorbrewer from "colorbrewer";

const colsPrefix =
  "a.the_geom_webmercator, a.wd22cd, a.lad22cd, a.lad22nm, a.wd22nm || ' (' || a.lad22nm || ', ' || b.rgn22nm ||')' wdla, ";

const ramppop = colorbrewer.OrRd[9];

const legendStops_Pop = [
  {
    id: 0,
    stops: [
      { value: 0, colour: ramppop[0], name: "Under 2000" },
      { value: 2000, colour: ramppop[1], name: "2-4000" },
      { value: 4000, colour: ramppop[2], name: "4-6000" },
      { value: 6000, colour: ramppop[3], name: "6-8000" },
      { value: 8000, colour: ramppop[4], name: "8-10000" },
      { value: 10000, colour: ramppop[5], name: "10-12000" },
      { value: 12000, colour: ramppop[6], name: "12-14000" },
      { value: 14000, colour: ramppop[7], name: "14-16000" },
      { value: 16000, colour: ramppop[8], name: "Over 16000" }
    ]
  }
];

const ramp7a = colorbrewer.Greens[9];

const legendStops7a = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: 0, colour: ramp7a[0], name: "Under 90%" },
      { value: 0.9, colour: ramp7a[1], name: "90-91%" },
      { value: 0.91, colour: ramp7a[2], name: "91-92%" },
      { value: 0.92, colour: ramp7a[3], name: "92-93%" },
      { value: 0.93, colour: ramp7a[4], name: "93-94%" },
      { value: 0.94, colour: ramp7a[5], name: "94-95%" },
      { value: 0.95, colour: ramp7a[6], name: "95-96%" },
      { value: 0.96, colour: ramp7a[7], name: "96-97%" },
      { value: 0.97, colour: ramp7a[8], name: "Over 97%" }
    ]
  }
];

const ramp1 = colorbrewer.Greens[9];

const legendStops04_10 = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: 0, colour: ramp1[0], name: "Under 50%" },
      { value: 0.5, colour: ramp1[1], name: "50-60%" },
      { value: 0.6, colour: ramp1[2], name: "60-70%" },
      { value: 0.7, colour: ramp1[3], name: "70-75%" },
      { value: 0.75, colour: ramp1[4], name: "75-80%" },
      { value: 0.8, colour: ramp1[5], name: "80-85%" },
      { value: 0.85, colour: ramp1[6], name: "85-90%" },
      { value: 0.9, colour: ramp1[7], name: "90-95%" },
      { value: 0.95, colour: ramp1[8], name: "Over 95%" }
    ]
  }
];

const ramp = colorbrewer.RdPu[9];

const legendStops0_022 = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: 0, colour: ramp[0], name: "None" },
      { value: 0.00001, colour: ramp[1], name: "0-1%" },
      { value: 0.01, colour: ramp[2], name: "1-2%" },
      { value: 0.02, colour: ramp[3], name: "2-3%" },
      { value: 0.03, colour: ramp[4], name: "3-4%" },
      { value: 0.04, colour: ramp[5], name: "4-6%" },
      { value: 0.06, colour: ramp[6], name: "6-8%" },
      { value: 0.08, colour: ramp[7], name: "8-10%" },
      { value: 0.1, colour: ramp[8], name: "Over 10%" }
    ]
  }
];

const ramp2 = colorbrewer.YlOrRd[9];

const legendStops0_10R = [
  {
    id: 0,
    stops: [
      { value: 0, colour: "#000000", name: "Under 20%" },
      { value: 0.2, colour: ramp2[8], name: "20-25%" },
      { value: 0.25, colour: ramp2[7], name: "25-30%" },
      { value: 0.3, colour: ramp2[6], name: "30-35%" },
      { value: 0.35, colour: ramp2[5], name: "35-40%" },
      { value: 0.4, colour: ramp2[4], name: "40-45%" },
      { value: 0.45, colour: ramp2[3], name: "45-50%" },
      { value: 0.5, colour: ramp2[2], name: "50-55%" },
      { value: 0.55, colour: ramp2[1], name: "55-60%" },
      { value: 0.6, colour: ramp2[0], name: "Over 60%" }
    ]
  }
];

const legendStops0_10 = [
  {
    id: 0,
    stops: [
      { value: 0, colour: ramp2[0], name: "Under 20%" },
      { value: 0.2, colour: ramp2[1], name: "20-30%" },
      { value: 0.3, colour: ramp2[2], name: "30-40%" },
      { value: 0.4, colour: ramp2[3], name: "40-50%" },
      { value: 0.5, colour: ramp2[4], name: "50-60%" },
      { value: 0.6, colour: ramp2[5], name: "60-70%" },
      { value: 0.7, colour: ramp2[6], name: "70-80%" },
      { value: 0.8, colour: ramp2[7], name: "80-90%" },
      { value: 0.9, colour: ramp2[8], name: "90-100%" }
    ]
  }
];

const legendStops0_5 = [
  {
    id: 0,
    stops: [
      { value: 0, colour: ramp2[0], name: "Under 5%" },
      { value: 0.05, colour: ramp2[1], name: "5-10%" },
      { value: 0.1, colour: ramp2[2], name: "10-15%" },
      { value: 0.15, colour: ramp2[3], name: "15-20%" },
      { value: 0.2, colour: ramp2[4], name: "20-25%" },
      { value: 0.25, colour: ramp2[5], name: "25-30%" },
      { value: 0.3, colour: ramp2[6], name: "30-40%" },
      { value: 0.4, colour: ramp2[7], name: "40-50%" },
      { value: 0.5, colour: ramp2[8], name: "Over 50%" }
    ]
  }
];

const ramp0_120 = colorbrewer.RdYlGn[6];

const legendStops3a = [
  {
    id: 0,
    stops: [
      { value: 0, colour: ramp0_120[5], name: "Under 1.4" },
      { value: 1.4, colour: ramp0_120[4], name: "1.4-1.8" },
      { value: 1.8, colour: ramp0_120[3], name: "1.8-2.2" },
      { value: 2.2, colour: ramp0_120[2], name: "2.2-2.6" },
      { value: 2.6, colour: ramp0_120[1], name: "2.6-3" },
      { value: 3, colour: ramp0_120[0], name: "Over 3" }
    ]
  }
];

const legendStops4a = [
  {
    id: 0,
    stops: [
      { value: 0, colour: ramp0_120[5], name: "Under 2.3" },
      { value: 2.3, colour: ramp0_120[4], name: "2.3-2.4" },
      { value: 2.4, colour: ramp0_120[3], name: "2.4-2.5" },
      { value: 2.5, colour: ramp0_120[2], name: "2.5-2.6" },
      { value: 2.6, colour: ramp0_120[1], name: "2.6-2.7" },
      { value: 2.7, colour: ramp0_120[0], name: "Over 2.7" }
    ]
  }
];

const legendStops4b = [
  {
    id: 0,
    stops: [
      { value: 0, colour: ramp0_120[5], name: "0-10" },
      { value: 10, colour: ramp0_120[4], name: "10-15" },
      { value: 15, colour: ramp0_120[3], name: "15-20" },
      { value: 20, colour: ramp0_120[2], name: "20-25" },
      { value: 25, colour: ramp0_120[1], name: "25-30" },
      { value: 30, colour: ramp0_120[0], name: "Over 30" }
    ]
  }
];

const legendStops350_550 = [
  {
    id: 0,
    stops: [
      { value: 0, colour: ramp0_120[0], name: "Under 42" },
      { value: 42, colour: ramp0_120[1], name: "42-44" },
      { value: 44, colour: ramp0_120[2], name: "44-46" },
      { value: 46, colour: ramp0_120[3], name: "46-48" },
      { value: 48, colour: ramp0_120[4], name: "48-50" },
      { value: 50, colour: ramp0_120[5], name: "Over 50" }
    ]
  }
];

// TDR (total dependency ratio)

const ramp0_175 = colorbrewer.RdPu[9];

const legendStops0_175 = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: 0.0, colour: ramp0_175[0], name: "0-30%" },
      { value: 0.3, colour: ramp0_175[1], name: "30-40%" },
      { value: 0.4, colour: ramp0_175[2], name: "40-50%" },
      { value: 0.5, colour: ramp0_175[3], name: "50-60%" },
      { value: 0.6, colour: ramp0_175[4], name: "60-70%" },
      { value: 0.7, colour: ramp0_175[5], name: "70-80%" },
      { value: 0.8, colour: ramp0_175[6], name: "80-90%" },
      { value: 0.9, colour: ramp0_175[7], name: "90-100%" },
      { value: 1, colour: ramp0_175[8], name: "Over 100%" }
    ]
  }
];

// SR (sex ratio)

const ramp07_23 = colorbrewer.RdBu[9];

const legendStops07_23 = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: 0.01, colour: ramp07_23[0], name: "Under 0.86" },
      { value: 0.86, colour: ramp07_23[1], name: "0.86-0.9" },
      { value: 0.9, colour: ramp07_23[2], name: "0.9-0.94" },
      { value: 0.94, colour: ramp07_23[3], name: "0.94-0.98" },
      { value: 0.98, colour: ramp07_23[4], name: "0.98-1.02" },
      { value: 1.02, colour: ramp07_23[5], name: "1.02-1.06" },
      { value: 1.06, colour: ramp07_23[6], name: "1.06-1.1" },
      { value: 1.1, colour: ramp07_23[7], name: "1.1-1.14" },
      { value: 1.14, colour: ramp07_23[8], name: "Over 1.14" }
    ]
  }
];

const jointablesql = " a, ie2 b where a.wd22cd = b.wd22cd";

export const INCLUSIVEECONOMY = {
  title: "Inclusive Economy",
  description:
    "The SIPHER Inclusive Economy (Ward Level) dataset: for researchers and policy actors to explore the extent and nature of economic inclusion across Great Britain.",
  downloadLink: "https://doi.org/10.17605/osf.io/s24ye",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + getCols() + " FROM wd22_gb" + jointablesql,
  buildingSql: "SELECT " + getCols() + " FROM wd22_gb_vmd23" + jointablesql,
  detailUrbanSql: "SELECT " + getCols() + " FROM wd22_gb_bua22" + jointablesql,
  urbanSql: "SELECT " + getCols() + " FROM wd22_gb_bua22" + jointablesql,
  defaultLayers: "1111000",
  extent: 10,
  popup: false,
  idField: "wd22cd",
  geogNameField: "wdla",
  geogNamePrefix: "",
  ladCodeField: "lad22cd",
  hoverFieldMetricMode: "All",
  metrics: getMetricList()
};

function getCols() {
  return (
    colsPrefix +
    getMetricList()
      .map(function(a) {
        return a.field;
      })
      .join(", ")
  );
}

function getMetricList() {
  //const minYear = 1997;
  //const maxYear = 2020;
  let list = [];
  let i = 0;
  let ic = 0;

  const cats = {
    pop: ["Population of ward", false, legendStops_Pop, "Population of ward."],
    indicator_1a: [
      "1A: Particip in paid employment",
      true,
      legendStops04_10,
      "Percentage of working-age people (aged 16-64) who are employed."
    ],
    indicator_1b: [
      "1B: Skills and qualifications",
      true,
      legendStops04_10,
      "Percentage of adults aged 20-49 with a Level 2 or higher National Vocational Qualification (NVQ)."
    ],
    indicator_2a: [
      "2A: Involu. excl from labour mkt",
      true,
      legendStops0_022,
      "Percentage of working-age people (aged 16-64) who are inactive due to ill health or disability."
    ],
    indicator_2b: [
      "2B: Digital exclusion",
      true,
      legendStops0_10,
      "Percentage of individuals who are classified as a) e-withdrawn, b) passive and uncommitted internet users, or c) settled offline communities; based on the Internet User Classification (IUC)."
    ],
    indicator_3a: [
      "3A: Wealth inequality",
      false,
      legendStops3a,
      "Ratio of median house prices in most expensive Lower Super Output Areas (LSOAs)/Data Zones (DZs) to median in least expensive."
    ],
    indicator_3b: [
      "3B: Physical connectivity",
      true,
      legendStops0_10,
      "Public transport accessibility measure, percentage of Lower Super Output Areas (LSOAs)/Data Zones (DZs) within the electoral ward area that are among the 50% least accessible LSOAs/DZs for each devolved nation."
    ],
    indicator_4a: [
      "4A: Earnings inequality",
      false,
      legendStops4a,
      "Ratio of weekly earnings for residents in full-time work between 80th and 20th percentiles."
    ],
    indicator_4b: [
      "4B: Housing affordability",
      false,
      legendStops4b,
      "Ratio of median house prices to median gross annual earnings."
    ],
    indicator_5a: [
      "5A: Poverty",
      true,
      legendStops0_5,
      "Percentage of children living in low income households (based on national relative threshold, before adjustment for housing costs)."
    ],
    indicator_5b: [
      "5B: Costs of living",
      true,
      legendStops0_022,
      "Percentage of fuel-poor households."
    ],
    indicator_6a: [
      "6A: Decent pay",
      true,
      legendStops04_10,
      "Percentage of employee jobs that are paid below the Real Living Wage (as defined by the Living Wage Foundation)."
    ],
    indicator_6b: [
      "6B: Inclusion in decision-making",
      true,
      legendStops0_10R,
      "Percentage of eligible voters participating in local elections."
    ],
    indicator_7a: [
      "7A: Job security/precarity",
      true,
      legendStops7a,
      "Percentage of employees in permanent work."
    ],
    tdr: [
      "Total dependency ratio (TDR)",
      true,
      legendStops0_175,
      "Ratio of the number of dependents aged zero to 15 and over 65, compared with the total population aged 16 to 64. Definition: (N(0-15) + N(65+)) / N(16-64)."
    ],
    sr: [
      "Sex ratio (SR)",
      false,
      legendStops07_23,
      "Ratio of the number of males to number of females. Definition: N(males) / N(females)."
    ],
    sf12mcs_male: [
      "Wellbeing score, mental, M",
      false,
      legendStops350_550,
      "SF-12 wellbeing score, mental health component, males only."
    ],
    sf12pcs_male: [
      "Wellbeing score, physical, M",
      false,
      legendStops350_550,
      "SF-12 wellbeing score, physical health component, males only."
    ],
    sf12mcs_female: [
      "Wellbeing score, mental, F",
      false,
      legendStops350_550,
      "SF-12 wellbeing score, mental health component, females only."
    ],
    sf12pcs_female: [
      "Wellbeing score, physical, F",
      false,
      legendStops350_550,
      "SF-12 wellbeing score, physical health component, females only."
    ],
    sf12mcs: [
      "Wellbeing score, mental",
      false,
      legendStops350_550,
      "SF-12 wellbeing score, mental health component"
    ],
    sf12pcs: [
      "Wellbeing score, physical",
      false,
      legendStops350_550,
      "SF-12 wellbeing score, physical health component"
    ]
  };

  const years = ["2019", "2020", "2021"];

  Object.keys(cats).forEach(function(key) {
    //for (let j = minYear; j <= maxYear; j++)
    for (const year in years) {
      const j = years[year];
      list.push({
        id: i,
        name: cats[key][0] + " " + j,
        description: cats[key][3],
        field: "ie" + j.substring(2) + "_" + key,
        display: cats[key][1] ? "Percentage" : "",
        decimals: 1,
        //legendStops: key == "wbr" ? legendStopsWBR : legendStops,
        legendStops: cats[key][2],
        legendOptions: [],
        additionalMetrics: Array(years.length)
          .fill()
          .map((v, k) => k + ic)
        //additionalMetrics: Array(maxYear-minYear+1).fill().map((v,k)=>k+ic)
      });
      i++;
    }
    ic = i;
  });
  return list;
}
