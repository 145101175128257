<template>
  <div id="map"></div>
</template>

<script>
import maplibregl from "maplibre-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "whatwg-fetch";
import { MAPBOXSTYLE } from "@/config/EmptyMapBoxStyle";

export default {
  name: "MapBoxMap",
  props: {
    sources: Array,
    layers: Array,
    lat: Number,
    long: Number,
    zoom: Number,
    mode: String,
    searchResult: Object,
    embed: Number
  },
  data() {
    return {
      map: null,
      draw: null
    };
  },
  components: {},
  methods: {
    mouseHover: function(e) {
      this.$emit("hover", this.getFeatures(e.point));
    },
    mouseClick: function(e) {
      if (this.mode == "select") {
        this.$emit("click", this.getFeatures(e.point));
      }
    },
    mapMove: function() {
      this.$emit("move", {
        lat: this.map.getCenter().toArray()[1],
        long: this.map.getCenter().toArray()[0],
        zoom: this.map.getZoom()
      });
    },
    getFeatures: function(point) {
      const vm = this;
      let features = [];

      [...this.layers, { name: "adhoc" }].forEach(element => {
        if (vm.map.getLayer(element.name)) {
          const query = vm.map.queryRenderedFeatures(point, {
            layers: [element.name]
          });
          if (query.length > 0) {
            features.push({ name: element.name, feature: query[0] });
          }
        }
      });

      return features;
    },
    addLayers: function() {
      const vm = this;

      //add ahdhoc polygon
      vm.map.addSource("adhoc", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [[]]
          }
        }
      });
      vm.map.addLayer({
        id: "adhoc",
        type: "fill",
        source: "adhoc",
        layout: {},
        paint: {
          "fill-color": "#088",
          "fill-outline-color": "#088",
          "fill-opacity": 0.2
        }
      });
      vm.map.addLayer({
        id: "adhoc-line2",
        type: "line",
        source: "adhoc",
        layout: {},
        paint: {
          "line-color": "#fff",
          "line-width": 5
        }
      });
      vm.map.addLayer({
        id: "adhoc-line1",
        type: "line",
        source: "adhoc",
        layout: {},
        paint: {
          "line-color": "#088",
          "line-width": 4
        }
      });

      let requests = [];
      this.sources.forEach(element => {
        const mapConfig = JSON.stringify({
          version: "1.3.0",
          layers: [
            {
              options: {
                cartocss_version: "2.1.1",
                cartocss: "#layer {  }",
                sql: element.sql,
                vector_extent: 4096
              }
            }
          ]
        });
        const config = `config=${encodeURIComponent(mapConfig)}`;
        requests.push(fetch(`${element.url}&${config}`));
      });

      Promise.all(requests)
        .then(results => Promise.all(results.map(e => e.json())))
        .then(responses => {
          for (let index = 0; index < responses.length; index++) {
            const source = this.sources[index];
            vm.map.addSource(source.name, {
              type: "vector",
              tiles: responses[index].metadata.tilejson.vector.tiles
            });
          }

          this.layers.forEach(layer => {
            const toadd = {
              id: layer.name,
              type: layer.type,
              source: layer.source,
              "source-layer": "layer0",
              layout: layer.layout,
              paint: layer.paint
            };

            if (layer.filter && layer.filter.length > 0) {
              toadd.filter = layer.filter;
            }
            if (layer.maxzoom) {
              toadd.maxzoom = layer.maxzoom;
            }
            if (layer.minzoom) {
              toadd.minzoom = layer.minzoom;
            }
            vm.map.addLayer(toadd, layer.layerBelow);
          });
          this.mapMove();
        });
    },
    setupMapControls: function() {
      this.map.addControl(
        new maplibregl.NavigationControl({ showCompass: false }),
        "top-left"
      );
      if (!this.embed > 0) {
        this.map.addControl(
          new maplibregl.GeolocateControl({
            positionOptions: {
              enableHighAccuracy: true
            },
            trackUserLocation: true
          }),
          "top-left"
        );
      }
      this.map.addControl(
        new maplibregl.ScaleControl({
          maxWidth: 150,
          unit: "metric"
        }),
        "bottom-right"
      );
    },
    startDrawing: function() {
      this.map.addControl(this.draw, "top-left");
      this.draw.deleteAll();
      this.draw.changeMode("draw_polygon");
    },
    finishDrawing: function(data) {
      this.draw.deleteAll();
      this.map.getSource("adhoc").setData(data.features[0]);
      this.$emit("featureDrawn", data.features[0]);
    },
    cancelDrawing: function() {
      this.map.removeControl(this.draw);
    },
    deleteDrawFeatures: function() {
      this.map.getSource("adhoc").setData({
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [[]]
        }
      });
      this.$emit("clickModeChange", "select");
    }
  },
  mounted() {
    const vm = this;
    this.map = new maplibregl.Map({
      container: "map",
      style: MAPBOXSTYLE,
      zoom: this.zoom,
      minZoom: 4,
      maxZoom: 16,
      //maxBounds: [
      //  [-16, 45],
      // [12, 65]
      //],
      center: [this.long, this.lat],
      pitchWithRotate: false,
      dragRotate: false,
      touchPitch: false,
      canvasContextAttributes: {
        preserveDrawingBuffer: true
      },
      attributionControl: false
    });
    this.setupMapControls();
    this.map.on("load", function() {
      vm.addLayers();
      vm.draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {},
        defaultMode: "draw_polygon",
        styles: [
          // line stroke
          {
            id: "gl-draw-line",
            type: "line",
            filter: [
              "all",
              ["==", "$type", "LineString"],
              ["!=", "mode", "static"]
            ],
            layout: {
              "line-cap": "round",
              "line-join": "round"
            },
            paint: {
              "line-color": "#088",
              "line-dasharray": [0.2, 2],
              "line-width": 3
            }
          },
          // polygon fill
          {
            id: "gl-draw-polygon-fill",
            type: "fill",
            filter: [
              "all",
              ["==", "$type", "Polygon"],
              ["!=", "mode", "static"]
            ],
            paint: {
              "fill-color": "#088",
              "fill-outline-color": "#088",
              "fill-opacity": 0.1
            }
          },
          // polygon outline stroke
          // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
          {
            id: "gl-draw-polygon-stroke-active",
            type: "line",
            filter: [
              "all",
              ["==", "$type", "Polygon"],
              ["!=", "mode", "static"]
            ],
            layout: {
              "line-cap": "round",
              "line-join": "round"
            },
            paint: {
              "line-color": "#088",
              "line-dasharray": [0.2, 2],
              "line-width": 3
            }
          },
          // vertex point halos
          {
            id: "gl-draw-polygon-and-line-vertex-halo-active",
            type: "circle",
            filter: [
              "all",
              ["==", "meta", "vertex"],
              ["==", "$type", "Point"],
              ["!=", "mode", "static"]
            ],
            paint: {
              "circle-radius": 5,
              "circle-color": "#FFF"
            }
          },
          // vertex points
          {
            id: "gl-draw-polygon-and-line-vertex-active",
            type: "circle",
            filter: [
              "all",
              ["==", "meta", "vertex"],
              ["==", "$type", "Point"],
              ["!=", "mode", "static"]
            ],
            paint: {
              "circle-radius": 3,
              "circle-color": "#088"
            }
          }
        ]
      });

      vm.map.on("draw.create", vm.finishDrawing);
      vm.map.on("draw.modechange", function() {
        vm.draw.changeMode("draw_polygon");
      });
      vm.map.on("mousemove", vm.mouseHover);
      vm.map.on("click", vm.mouseClick);
      vm.map.on("zoomend", vm.mapMove);
      vm.map.on("moveend", vm.mapMove);
    });
  },
  watch: {
    mode: function(newValue, oldValue) {
      if (newValue == "draw") {
        this.startDrawing();
      }
      if (oldValue == "draw") {
        this.cancelDrawing();
      }

      if (newValue == "delete") {
        this.deleteDrawFeatures();
      }
    },
    searchResult: function(newValue) {
      if (this.map && newValue.lat && newValue.long && newValue.zoom) {
        this.map.flyTo({
          center: [newValue.long, newValue.lat],
          zoom: newValue.zoom
        });
      }
    },
    layers: function(newValue) {
      newValue.forEach(element => {
        if (element.layout) {
          Object.keys(element.layout).forEach(key => {
            this.map.setLayoutProperty(element.name, key, element.layout[key]);
          });
        }
        if (element.filter) {
          this.map.setFilter(element.name, null);
          if (element.filter && element.filter.length >= 1) {
            this.map.setFilter(element.name, element.filter);
          }
        }
        if (element.paint) {
          Object.keys(element.paint).forEach(key => {
            this.map.setPaintProperty(element.name, key, element.paint[key]);
          });
        }
      });
    }
  }
};
</script>

<style scoped>
#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
button:focus {
  box-shadow: 0;
}
</style>

<style>
.maplibregl-ctrl-top-left {
  top: 0;
  left: 0;
}
</style>
