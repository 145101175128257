import colorbrewer from "colorbrewer";

const cols =
  "a.the_geom_webmercator, a.geogcode, a.geogname, a.ladcode, a.ladname, " +
  "b.ndvi_median, b.evi_median, b.veg_frac";

const jointablesql = " a, ndvi_2025_v1 b where a.geogcode = b.lsoa21cd";

var ramp = colorbrewer.PiYG[10];

const legendStopsVeg = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#888888", name: "No data" },
      { value: 0, colour: ramp[0], name: "Under 0.1" },
      { value: 0.1, colour: ramp[1], name: "0.1-0.2" },
      { value: 0.2, colour: ramp[2], name: "0.2-0.3" },
      { value: 0.3, colour: ramp[3], name: "0.3-0.4" },
      { value: 0.4, colour: ramp[4], name: "0.4-0.5" },
      { value: 0.5, colour: ramp[5], name: "0.5-0.6" },
      { value: 0.6, colour: ramp[6], name: "0.6-0.7" },
      { value: 0.7, colour: ramp[7], name: "0.7-0.8" },
      { value: 0.8, colour: ramp[8], name: "0.8-0.9" },
      { value: 0.9, colour: ramp[9], name: "0.9-1" }
    ]
  }
];

ramp = colorbrewer.PiYG[5];

const legendStopsEVI = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#888888", name: "No data" },
      { value: 0, colour: ramp[0], name: "Under 0.05" },
      { value: 0.05, colour: ramp[1], name: "0.05-0.1" },
      { value: 0.1, colour: ramp[2], name: "0.1-0.15" },
      { value: 0.15, colour: ramp[3], name: "0.15-0.2" },
      { value: 0.2, colour: ramp[4], name: "Over 0.2" }
    ]
  }
];

const legendStopsFrac = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#888888", name: "No data" },
      { value: 0, colour: ramp[0], name: "Under 0.6" },
      { value: 0.6, colour: ramp[1], name: "0.6-0.7" },
      { value: 0.7, colour: ramp[2], name: "0.7-0.8" },
      { value: 0.8, colour: ramp[3], name: "0.8-0.9" },
      { value: 0.9, colour: ramp[4], name: "0.9-1" }
    ]
  }
];
export const VEGETATION = {
  title: "Vegetation",
  description:
    "Normalised Difference Vegetation Index (NDVI), Enhanced Vegetation Index (EVI) and Vegetation Fraction, for each LSOA21/equivalent across the UK.",
  downloadLink:
    "https://data.cdrc.ac.uk/dataset/small-area-uk-vegetation-indices",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM lsoa21_uk" + jointablesql,
  buildingSql: "SELECT " + cols + " FROM lsoa21_uk_vmd23" + jointablesql,
  detailUrbanSql: "SELECT " + cols + " FROM lsoa21_uk_bua24" + jointablesql,
  urbanSql: "SELECT " + cols + " FROM lsoa21_uk_bua24" + jointablesql,
  defaultLayers: "1111000",
  initialValue: 0,
  extent: 9,
  popup: false,
  idField: "geogcode",
  geogNameField: "geogname",
  ladCodeField: "ladcode",
  hoverFieldMetricMode: "All",
  metrics: [
    {
      id: 0,
      name: "Normalized Difference Vegetation Index (NDVI) Median",
      field: "ndvi_median",
      decimals: 2,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStopsVeg
    },
    {
      id: 1,
      name: "Enhanced Vegetation Index (EVI) Median",
      field: "evi_median",
      decimals: 2,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStopsEVI
    },
    {
      id: 2,
      name: "Vegetation Fraction",
      field: "veg_frac",
      decimals: 2,
      legendOptions: [{ id: 1, name: "Value" }],
      legendStops: legendStopsFrac
    }
  ]
};
